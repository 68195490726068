import React, {useState} from 'react';
import {string, bool, objectOf, any, arrayOf} from "prop-types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Title from "../Title";
import Image from "../ImageQueries/GeneralImages";
import CtaSecondary from "../Buttons/Secondary";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

const Teams = (props) => {
    const {className, presentation} = props
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className='relative z-0 overflow-hidden max-w-[1440px] lg:mx-[160px] xl:mx-auto lg:px-0 xl:px-[160px] mx-[16px]'>
            <Title level={2} withBlueSymbol symbol='.' className='text-center text-[42px] leading-[140%]'>Our teams</Title>
            <div className='mx-auto'>
                <Tabs className='bg-white border-none mb-8'
                      variant="scrollable"
                      value={value}
                      scrollButtons="auto"
                      onChange={handleChange}
                >
                    {presentation.map((item, idx) => (
                        <Tab
                            value={idx}
                            label={item.teamName}
                            disableRipple
                            className={`mx-0 text-[16px] shadow-none border-none bg-white text-lg hover:cursor-pointer rounded-full py-0 px-[24px]  ${value === idx && 'text-primary bg-[#E8F0FF] '}`} key={`tab_for_${item.teamName}`} />
                    )) }
                </Tabs>
                {presentation.map((item, idx) => {
                    return (
                        <TabPanel value={value} index={idx} key={`values_for_${item.teamName}_tab`}>
                            <div className='grid grid-2-3-a xl:gap-32 gap-[42px]'>
                                <Image className='rounded-[32px]' imageName={item.img} />
                                <div className='sm:max-w-full xl:max-w-[350px] z-10'>
                                    <Title level={3} className='font-bold text-[24px] leading-[130%]' style={{ marginBottom: 0}}>{item.teamName}</Title>
                                    <p className='font-sans text-black-200 text-[16px] leading-[160%]'>{item.teamAbout}</p>
                                    <span><p className='font-sans text-black-200 font-bold text-[16px] leading-[160%]'>Technologies: <span className='font-light text-[16px] leading-[160%]'>{item.stack}</span></p></span>
                                    <span><p className='font-sans text-black-200 font-bold text-[16px] leading-[160%]'>{item.teamMate}, <span className='font-light text-[16px] leading-[160%]'>{item.matePosition}</span></p></span>
                                </div>
                            </div>
                        </TabPanel>
                    )
                })}
            </div>
            <CtaSecondary className='mx-auto md:mt-16 mt-8 mb-[70px]' title='Check our vacancies' large target='/careers' />
        </div>
    );
};
Teams.propTypes = {
    className: string,
    presentation: arrayOf(any)
};

Teams.defaultProps = {
    className: '',
    presentation: []
};

export default Teams;
