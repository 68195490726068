import React from 'react';
import PropTypes, {string, bool, oneOf, arrayOf} from "prop-types";
import Title from "../Title";
import CtaPrimary from "../Buttons/Button";
import Carousel from 'nuka-carousel';
import Image from "../ImageQueries/GeneralImages";

const Offices = (props) => {
    const {className, imageArray} = props
    return (
        <div className='grid xl:grid-cols-2 grid-cols-1 min-h-[450px] overflow-hidden '>
            <div className='2xl:justify-items-end 2xl:pr-[120px] bg-black lg:px-40 lg:pb-[92px] pb-24 md:px-8 px-[16px] grid gap-0 xl:gap-0 md:gap-12 grid-cols-1 md:grid-cols-2 xl:grid-cols-1'>
                <div className='max-w-[790px]'>
                    <p className='font-sans text-black-500 leading-8 mb-[40px] mt-[100px]'>Our offices</p>
                    <Title level={2} withBlueSymbol symbol='.' className='text-white text-[42px] leading-[140%]' style={{ marginBottom: 24}}>Kraków</Title>
                    <p className='font-sans text-black-500 leading-8 text-[16px] leading-[160%] lg:max-w-[450px]'>Modern space, green patio, canteen and cafe, food trucks court...Work in our Kraków office! Check out excellent facilities at the V Offices and join us there.</p>

                </div>
                <div className='mt-0 md:mt-[140px] xl:mt-0 max-w-[790px]'>
                    <Title level={2} withBlueSymbol symbol='.' className='text-white text-[42px] leading-[140%]' style={{ marginBottom: 24}}>Gdańsk</Title>
                    <p className='font-sans text-black-500 text-[16px] leading-[160%] lg:max-w-[450px]'>Join our new office in Gdańsk! You will work with us at the excellent Neon Flex complex where you will find a gym and swimming pool, beauty salons, kindergartens, and many food facilities.</p>
                </div>
                <CtaPrimary title='Contact us' target={'/contact-us'} className='mt-0 cta-p py-2.5 px-6 max-h-[58px] mt-[40px] px-10 2xl:mr-[270px]'/>
            </div>
            <Carousel
                className='h-full w-full max-h-[635px] md:max-h-[900px] lg:max-h-[1300px] xl:max-h-[890px] xl:order-last md:order-first order-last'
                wrapAround
                swiping
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                defaultControlsConfig={{
                    pagingDotsStyle: {
                        fill: 'white'
                    }
                }}
            >
                {imageArray.map((img, idx) => {
                    return (
                        <Image className='object-fill h-full w-full md:min-h-[900px] lg:min-h-[1005px]' imageName={img.img} key={`office_carousel_image_${idx.toString()}`}/>
                    )
                })}
            </Carousel>
        </div>
    );
};
Offices.propTypes = {
    className: string,
    imageArray: arrayOf(PropTypes.any)
};

Offices.defaultProps = {
    className: '',
    imageArray: []
};

export default Offices;
